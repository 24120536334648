import { StateCode } from '../constants/states'

import { LineOfBusinessCode } from './line-of-business'

export interface GaEcommerceEventModel {
    readonly transction_Id?: number //orderID
    readonly currency: 'USD'
    readonly campaignCode?: string
    readonly saleType?: GaSalesType
    readonly coupon?: string | null
    readonly items: ReadonlyArray<ItemEventModel>
}
//Coordinate Changes with GA Ecommerce
export interface ItemEventModel {
    readonly index?: number
    readonly item_id?: number
    readonly item_name?: string //Product Display Name?
    readonly discount?: number
    readonly price?: number
    readonly unitOfMeasure?: string
    readonly priceType?: string
    readonly cancelFee?: number
    readonly termLength?: number
    readonly ulob?: string //utilityLine Line of Business (Add AEP handler)
    readonly lob?: LineOfBusinessCode
    readonly state?: StateCode
    readonly confirmationNumber?: string
}

export enum GaEvent {
    Purchase = 'purchase',
    Login = 'login',
    RemoveFromCart = 'remove_from_cart',
    AddToCart = 'add_to_cart',
    AddContactInfo = 'add_contact_info',
    AddServiceAddress = 'add_service_address',
    BeginCheckout = 'begin_checkout',
    AddProductDetails = 'add_product_details',
    CreditCheck = 'credit_check',
    SelectRate = 'select_rate',
    ReviewOrder = 'review_order',
    StartDate = 'start_date',
    AddPaymentMethod = 'add_payment_method',
}

export enum GaSalesType {
    New = 'New',
    Renewal = 'Renewal',
    RateChange = 'RateChange',
}
