import * as React from 'react'
import { FormRenderProps } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'

import { SubmitButton } from '@igs-web/common-components/_molecules/final-form/submit-button'
import { ButtonSection } from '@igs-web/common-ui-components/_atoms/buttons/button-section'
import { BoxedErrorAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { HtmlForm } from '@igs-web/common-ui-components/_atoms/forms/html-form'
import { TypedForm } from '@igs-web/common-ui-components/_atoms/forms/typed-form'
import { EmailField } from '@igs-web/common-ui-components/_molecules/form-inputs/email-field'
import { PasswordField } from '@igs-web/common-ui-components/_molecules/form-inputs/password-field'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { SolarMyAccountLanguage } from '@igs-web/common-ui-components/_molecules/solar-my-account-language'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { required } from '@igs-web/common-utilities/utilities/form-validation'

import { useCompany } from '../company/hooks/useCompany'
import { UserSelectors, userActions } from '../user/user-redux'

import { LoginFormSubtitle } from './login-form-subtitle'

export interface LoginFormFields {
    readonly username: string
    readonly password: string
}

const WideLayoutSubmitButtonContainer = styled.div`
    padding-bottom: ${Spacing.Large};
    & > button {
        border-radius: 0;
        width: 100%;
    }
`
const StyledForm = styled(HtmlForm)`
    .form-label-group {
        .input-field:not(.is-invalid) {
            margin-bottom: 37px;
        }
    }
`

export const LoginForm = ({ onSubmit, uniqueId, email, useFormErrorMessaging }: Props): JSX.Element => {
    const { features } = useCompany()
    const dispatch = useDispatch()
    const { hasSolarMyAccount } = features
    const userProfileError = useSelector(UserSelectors.selectUserProfileLoadError)
    const logoutMessage = useSelector(UserSelectors.selectLogoutMessage)
    const [logoutMessageState, setLogoutMessage] = React.useState<string | undefined>(undefined)

    React.useEffect(() => {
        if (logoutMessage && useFormErrorMessaging) {
            setLogoutMessage(logoutMessage)
            dispatch(userActions.setLogoutMessage(undefined))
        }
    }, [])

    return (
        <TypedForm<LoginFormFields> onSubmit={onSubmit}>
            {({ handleSubmit, submitError }: FormRenderProps): JSX.Element => (
                <StyledForm onSubmit={handleSubmit}>
                    <FormErrorMessage
                        useFormErrorMessaging={useFormErrorMessaging}
                        submitError={submitError}
                        userProfileError={userProfileError}
                        logoutMessageState={logoutMessageState}
                    />
                    {features.displayLoginPageSubheader && (
                        <LoginFormSubtitle>Log in to access your account details, renew, or shop for additional products.</LoginFormSubtitle>
                    )}

                    <EmailField<LoginFormFields>
                        autoFocus={!email}
                        name="username"
                        id={`myaccount-login-email-${uniqueId}`}
                        dataTestId={`myaccount-login-email-${uniqueId}`}
                        validate={required('Email Address is required')}
                        label="Email Address"
                        defaultValue={email}
                        required={true}
                        aria-required="true"
                        type="email"
                    />
                    <PasswordField<LoginFormFields>
                        autoFocus={!!email}
                        name="password"
                        id={`myaccount-login-password-${uniqueId}`}
                        dataTestId={`myaccount-login-password-${uniqueId}`}
                        validate={required('Password is required')}
                        label="Password"
                        hideError={false}
                        required={true}
                        aria-required="true"
                    />

                    <ButtonSection alignment="center" className="login-button-container">
                        {features.displayLoginPageSubheader ? (
                            <SubmitButton id={`myaccount-login-submit-${uniqueId}`} dataTestId={`myaccount-login-submit-${uniqueId}`}>
                                LOG IN
                            </SubmitButton>
                        ) : (
                            <WideLayoutSubmitButtonContainer>
                                <SubmitButton id={`myaccount-login-submit-${uniqueId}`} dataTestId={`myaccount-login-submit-${uniqueId}`}>
                                    Sign In{' '}
                                </SubmitButton>
                            </WideLayoutSubmitButtonContainer>
                        )}
                    </ButtonSection>
                    <SolarMyAccountLanguage show={!!submitError && hasSolarMyAccount} />
                </StyledForm>
            )}
        </TypedForm>
    )
}

const FormErrorMessage = ({ useFormErrorMessaging, submitError, userProfileError, logoutMessageState }: FormErrorMessageProps) => {
    const getErrorMessage = (): string | undefined => {
        if (submitError) {
            return submitError.toString()
        } else if (userProfileError) {
            return userProfileError
        } else if (logoutMessageState) {
            return logoutMessageState
        } else {
            return undefined
        }
    }
    if (!useFormErrorMessaging || !getErrorMessage()) {
        return <></>
    } else {
        return (
            <>
                <BoxedErrorAlert>{getErrorMessage()}</BoxedErrorAlert>
                <GridSpacer spacing={Spacing.Large} />
            </>
        )
    }
}

interface FormErrorMessageProps {
    readonly useFormErrorMessaging: boolean
    readonly submitError: unknown | undefined
    readonly userProfileError: string | undefined
    readonly logoutMessageState: string | undefined
}
interface Props {
    readonly uniqueId: string
    readonly onSubmit: (values: LoginFormFields) => Promise<object | void>
    readonly email?: string
    readonly useFormErrorMessaging: boolean
}
