import { clearFromStorage, getFromStorage, putInStorage } from './storage-utilities'

export const authTokenKey = 'auth-token'

export const clearAuthToken = () => {
    clearFromStorage(authTokenKey, true)
}

export const hasValidAuthToken = () => {
    const token = getAuthToken()
    if (!token || isAuthTokenExpired(token)) {
        return false
    }
    return true
}

export const getAuthToken = (): string | undefined => {
    return getFromStorage(authTokenKey, true)
}

const getAuthTokenExpirationDate = (token: string): Date => {
    const decode = JSON.parse(atob(token.split('.')[1]))
    return new Date(decode.expiration)
}

export const setUserAuthToken = (token: string) => putInStorage(token, authTokenKey, true)

export const isAuthTokenExpired = (token: string): boolean => {
    const expirationDate = getAuthTokenExpirationDate(token)
    return expirationDate < new Date()
}
